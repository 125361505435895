import type { MenuDestinationParameters } from '~/types/menu'

export function getFullw2wURL(path: string, locale: string) {
  const baseUrl = locale === 'en' ? 'https://where2watch.ca' : 'https://ouvoir.ca'

  return new URL(path, baseUrl).toString()
}

export function getMoviesPageName(type?: string) {
  switch (type) {
    case 'upcoming_releases':
      return 'movies-upcoming-releases'
      break
    case 'featured':
      return 'movies-featured'
      break
    case 'weekly_release':
    default:
      return 'movies-weekly-releases'
  }
}

export function getMenuDestinationURL(
  destination: string,
  parameters: MenuDestinationParameters | null,
  anchors: string | null
) {
  const localePath = useLocalePath()

  switch (destination) {
    case 'home':
      return localePath({ name: 'index', ...(anchors && { hash: `#${anchors}` }) })
    case 'articles':
      return localePath({ name: 'articles', ...(anchors && { hash: `#${anchors}` }) })
    case 'about-us':
      return localePath({
        name: 'about-us',
        ...(anchors && { hash: `#${anchors}` })
      })
    case 'brands':
      return localePath({
        name: 'brands',
        ...(anchors && { hash: `#${anchors}` })
      })
    case 'movies': {
      return localePath({
        name: getMoviesPageName(parameters?.type),
        query: {
          ...(parameters?.release_type && { rt: parameters.release_type }),
          ...(parameters?.rating && { ra: parameters.rating }),
          ...(parameters?.country && { co: parameters.country }),
          ...(parameters?.genre && { genre: parameters.genre }),
          ...(parameters?.sort && { sort: parameters.sort })
        },
        ...(anchors && { hash: `#${anchors}` })
      })
    }
    case 'tv_schedule':
      return localePath({
        name: 'tv-schedule'
      })
  }
}
